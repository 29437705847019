import '@mrhenry/wp--bugsnag-config';

import { initMenuButtons } from '@mrhenry/wp--mr-interactive';

import MrDeltaTime from '@mrhenry/wp--mr-delta-time';
customElements.define( 'mr-delta-time', MrDeltaTime );

/* Modules */
import './modules/input-sink';
import './modules/mr-accordion';
import './modules/mr-blocked-content';
import './modules/mr-carousel';
import './modules/navigation-overlay';

initMenuButtons();
