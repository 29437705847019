class MrCarousel extends HTMLElement {
	// MARK: properties.

	#currentIndex = 0;

	#itemWidth = 0;

	#clickHandler = ( e: MouseEvent ): void => {
		// Ignore clicks with modifier keys: shift, ctrl, alt,...
		if ( e.metaKey ) {
			return;
		}

		// Check if target exist and is instance of HTMLElement.
		if ( !e.target || !( e.target instanceof HTMLElement ) ) {
			return;
		}

		// Unknown trigger, not handling this event.
		if (
			!e.target.hasAttribute( 'data-carousel-next' ) &&
			!e.target.hasAttribute( 'data-carousel-previous' ) &&
			!e.target.hasAttribute( 'data-carousel-goto' )
		) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		if ( e.target.hasAttribute( 'data-carousel-next' ) ) {
			this.goToNextItem();

			return;
		}

		if ( e.target.hasAttribute( 'data-carousel-previous' ) ) {
			this.goToPreviousItem();

			return;
		}

		if ( e.target.hasAttribute( 'data-carousel-goto' ) ) {
			this.goToItem( e.target.getAttribute( 'data-carousel-goto' ) ?? '0' );

			return;
		}
	};

	// MARK: lifecycle.
	connectedCallback() {
		// arrow clickhandler
		this.addEventListener( 'click', this.#clickHandler );

		this.#itemWidth = this.offsetWidth;

		requestAnimationFrame( () => {
			let resizeThrottle = false;

			window.addEventListener( 'resize', () => {
				if ( resizeThrottle ) {
					return;
				}

				resizeThrottle = true;

				requestAnimationFrame( () => {
					this.#itemWidth = this.offsetWidth;
					const container = this.querySelector( '[data-carousel-items]' ) as HTMLElement;
					container.style.transform = `translateX(-${this.#itemWidth * this.#currentIndex}px)`;
					resizeThrottle = false;
				} );
			} );

		} );
	}

	disconnectedCallback() {
		this.removeEventListener( 'click', this.#clickHandler );

		// Reset States.
		this.#currentIndex = 0;
	}

	// MARK: methods.

	private goToItem( index: string ) {
		let newIndex = parseInt( index, 10 );
		if ( newIndex === this.#currentIndex ) {
			return;
		}

		if ( Number.isNaN( newIndex ) ) {
			newIndex = 0;
		}

		if ( 0 > newIndex ) {
			newIndex = 0;
		}

		if ( 0 < newIndex ) {
			const itemsLength = this.querySelectorAll( '[data-carousel-item]' ).length;
			if ( newIndex > itemsLength ) {
				newIndex = itemsLength - 1;
			}
		}

		this.#currentIndex = newIndex;
		this.updateState( newIndex );
	}

	private goToPreviousItem() {
		const items = this.querySelectorAll( '[data-carousel-item]' );
		const newIndex = indexMinusOne( this.#currentIndex, items.length, true );
		if ( newIndex === this.#currentIndex ) {
			return;
		}

		this.#currentIndex = newIndex;
		this.updateState( newIndex );
		this.transition();
	}

	private goToNextItem() {
		const items = this.querySelectorAll( '[data-carousel-item]' );
		const newIndex = indexPlusOne( this.#currentIndex, items.length, true );
		if ( newIndex === this.#currentIndex ) {
			return;
		}

		this.#currentIndex = newIndex;
		this.updateState( newIndex );
		this.transition();

	}

	private transition( ) {
		const container = this.querySelector( '[data-carousel-items]' ) as HTMLElement;
		const items = container.querySelectorAll( '[data-carousel-item]' );

		items?.forEach( ( item ) => {
			if ( !( item instanceof HTMLElement ) ) {
				return;
			}
			item.style.opacity = '0';
		} );

		container.style.transform = `translateX(-${this.#itemWidth * this.#currentIndex}px)`;

		const currentItem = items[this.#currentIndex];
		if ( !currentItem || !( currentItem instanceof HTMLElement ) ) {
			return;
		}

		currentItem.style.opacity = '1';
	}


	private updateState( index: number ) {
		this.updateStateForSelector( 'item', index );
		this.updateStateForSelector( 'goto', index );
	}

	private updateStateForSelector( selector: string, index: number ) {
		const items = this.querySelectorAll( `[data-carousel-${selector}]` );
		const length = items.length;

		// Check if items not available.
		if ( 2 > length ) {
			return;
		}

		// Get current, previous and next items.
		const previous = items[indexMinusOne( index, length, true )];
		const current = items[index];
		const next = items[indexPlusOne( index, length, true )];

		// Set attributes.
		// First reset all items.
		items.forEach( ( item ) => {
			item.removeAttribute( `data-carousel-${selector}-previous` );
			item.removeAttribute( `data-carousel-${selector}-current` );
			item.removeAttribute( `data-carousel-${selector}-next` );

			const label = item.querySelector( 'a[href]' );
			if ( !label || document.activeElement === label ) {
				return;
			}

			label.setAttribute( 'tabindex', '-1' );
		} );

		previous.setAttribute( `data-carousel-${selector}-previous`, '' );
		next.setAttribute( `data-carousel-${selector}-next`, '' );

		current.setAttribute( `data-carousel-${selector}-current`, '' );
	}
}

customElements.define( 'mr-carousel', MrCarousel );

function indexMinusOne( i: number, maxValue: number, looping: boolean ): number {
	if ( 2 > maxValue ) {
		return 0;
	}

	let index = i;

	index--;

	if ( 0 > index ) {
		if ( looping ) {
			return maxValue - 1;
		}

		return 0;

	}

	return index;
}

function indexPlusOne( i: number, maxValue: number, looping: boolean ): number {
	if ( 2 > maxValue ) {
		return 0;
	}

	let index = i;

	index++;

	if ( index >= maxValue ) {
		if ( looping ) {
			return 0;
		}

		return maxValue - 1;

	}

	return index;
}
