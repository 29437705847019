type deltaTimeDiff = {
	z: 0|1|2|3|4|5|6|7
	delta: number
}

export default class MrDeltaTime extends HTMLElement {
	get utcTimeSeconds(): number|null {
		return this.extractIntValue( this.getAttribute( 'utc-time-seconds' ) );
	}

	getWording( z: number, singular: boolean, future: boolean ): string | null {
		if ( singular && future ) {
			return this.getAttribute( `z${z}-singular-future` );
		}

		if ( singular && !future ) {
			return this.getAttribute( `z${z}-singular-past` );
		}

		if ( !singular && future ) {
			return this.getAttribute( `z${z}-plural-future` );
		}

		if ( !singular && !future ) {
			return this.getAttribute( `z${z}-plural-past` );
		}

		return null;
	}

	connectedCallback(): void {
		requestAnimationFrame( () => {
			const utcTimeSeconds = this.utcTimeSeconds;
			if ( !utcTimeSeconds ) {
				return;
			}

			const diff = this.diff( utcTimeSeconds, Math.floor( ( Date.now() ) / 1000 ) );

			const format = this.format( diff );

			// If the format is empty, there is nothing todo.
			// The original content is fine.
			if ( !format ) {
				return;
			}

			// This element is a one-off so we replace the parent content the formatted time diff.
			// I hope this doesn't throw errors. If it does we need to replace the content later in the CE lifetime.
			const parent = this.parentNode;
			if ( parent ) {
				const container = document.createElement( 'span' );
				container.innerHTML = this.prettyDeltaTime( diff, format );


				requestAnimationFrame( () => {
					parent.replaceChild(
						container,
						this
					);
				} );
			}
		} );
	}

	extractIntValue( value: string|null ): number|null {
		if ( !value ) {
			return null;
		}

		const parsed = parseInt( value, 10 );
		if ( isNaN( parsed ) ) {
			return null;
		}

		return parsed;
	}

	// prettyDeltaTime does a find and replace for "{delta}"
	// Don't add extra whitespace.
	prettyDeltaTime( diff: deltaTimeDiff, format: string ): string {
		return format.replace( new RegExp( '{delta}', 'g' ), Math.abs( diff.delta ).toString() );
	}

	// format determines the correct format string based on the "z" and "delta" values.
	format( diff: deltaTimeDiff ): string|null {
		if ( 7 === diff.z ) {
			// now
			return this.getAttribute( 'z7' );
		}

		if ( 0 < diff.delta ) {
			// future
			if ( 1 === Math.abs( diff.delta ) ) {
				return this.getWording( diff.z, true, true );
			}

			return this.getWording( diff.z, false, true );
		}

		// past
		if ( 1 === Math.abs( diff.delta ) ) {
			return this.getWording( diff.z, true, false );
		}

		return this.getWording( diff.z, false, false );
	}

	// diff determines the time difference
	//
	// It is used for "1 month ago" style outputs
	diff( inputSeconds: number, nowSeconds: number ): deltaTimeDiff {
		const result : deltaTimeDiff = {
			z: 7, // highest precission
			delta: 0, // no difference
		};

		const diffSeconds = inputSeconds - nowSeconds;
		const diffMinutes = diffSeconds / 60;
		const diffHours = diffMinutes / 60;
		const diffDays = diffHours / 24;
		const diffWeeks = diffDays / 7;
		const diffMonths = diffDays / 30.4166666667; // (365 / 12)
		const diffYears = diffDays / 365;

		if ( 0.999 <= Math.abs( diffYears ) ) { // don't compare with 1 because of floating point math
			result.z = 0;

			if ( 0 < diffYears ) {
				result.delta = Math.floor( diffYears + 0.001 );
			} else {
				result.delta = Math.ceil( diffYears - 0.001 );
			}

			return result;
		}

		if ( 0.99 <= Math.abs( diffMonths ) ) { // don't compare with 1 because of floating point math
			result.z = 1;

			if ( 0 < diffMonths ) {
				result.delta = Math.floor( diffMonths + 0.01 );
			} else {
				result.delta = Math.ceil( diffMonths - 0.01 );
			}

			return result;
		}

		if ( 0.99 <= Math.abs( diffWeeks ) ) { // don't compare with 1 because of floating point math
			result.z = 2;

			if ( 0 < diffWeeks ) {
				result.delta = Math.floor( diffWeeks + 0.01 );
			} else {
				result.delta = Math.ceil( diffWeeks - 0.01 );
			}

			return result;
		}

		if ( 0.99 <= Math.abs( diffDays ) ) { // don't compare with 1 because of floating point math
			result.z = 3;

			if ( 0 < diffDays ) {
				result.delta = Math.floor( diffDays + 0.01 );
			} else {
				result.delta = Math.ceil( diffDays - 0.01 );
			}

			return result;
		}

		if ( 0.99 <= Math.abs( diffHours ) ) { // don't compare with 1 because of floating point math
			result.z = 4;

			if ( 0 < diffHours ) {
				result.delta = Math.floor( diffHours + 0.01 );
			} else {
				result.delta = Math.ceil( diffHours - 0.01 );
			}

			return result;
		}

		if ( 0.99 <= Math.abs( diffMinutes ) ) { // don't compare with 1 because of floating point math
			result.z = 5;

			if ( 0 < diffMinutes ) {
				result.delta = Math.floor( diffMinutes + 0.01 );
			} else {
				result.delta = Math.ceil( diffMinutes - 0.01 );
			}

			return result;
		}

		if ( 0.99 <= Math.abs( diffSeconds ) ) { // don't compare with 1 because of floating point math
			result.z = 6;

			if ( 0 < diffSeconds ) {
				result.delta = Math.floor( diffSeconds + 0.01 );
			} else {
				result.delta = Math.ceil( diffSeconds - 0.01 );
			}

			return result;
		}

		return result;
	}

	// This is not perfect as it uses an incorrect number of seconds for a week.
	// But this is a close enough approximation and there is no better way.
	getWeekISO8601( date: Date, dowOffset = 0 ): number {
		// https://www.epoch-calendar.com/support/getting_iso_week.html
		// getWeekISO8601() was developed by Nick Baicoianu at MeanFreePath: http://www.epoch-calendar.com
		const newYear = new Date( date.getFullYear(), 0, 1 );
		let day = newYear.getDay() - dowOffset; // the day of week the year begins on
		if ( 0 > day ) {
			day = day + 7;
		}

		const daynum = Math.floor( ( date.getTime() - newYear.getTime() - ( date.getTimezoneOffset() - newYear.getTimezoneOffset() ) * 60000 ) / 86400000 ) + 1;
		let weeknum;

		// if the year starts before the middle of a week
		if ( 4 > day ) {
			weeknum = Math.floor( ( daynum + day - 1 ) / 7 ) + 1;

			if ( 52 < weeknum ) {
				const nYear = new Date( date.getFullYear() + 1, 0, 1 );
				let nday = nYear.getDay() - dowOffset;
				if ( 0 > nday ) {
					nday = nday + 7;
				}

				// if the next year starts before the middle of
				// the week, it is week #1 of that year
				if ( 4 > nday ) {
					weeknum = 1;
				} else {
					weeknum = 53;
				}
			}
		} else {
			weeknum = Math.floor( ( daynum + day - 1 ) / 7 );
		}

		return weeknum;
	}
}
